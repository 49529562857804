import "../css/app.css";
import { burgerToggler } from "./new/burger";
import { JsUtils } from './perso/jsUtils';
import "./perso/Slider";

window.JsUtils = new JsUtils

window.addEventListener('DOMContentLoaded', () => {
    burgerToggler()
})
