export function burgerToggler(){
    const burger = document.querySelector('#js-burger');
    const mobilNav = document.querySelector('#mobil-nav')

    // on click toggle burger shape or cross shape
    //          toggle nav hidden 
    burger.addEventListener('click', () =>  {
        burger.classList.toggle('burger-open');
        mobilNav.classList.toggle('hidden')
    })
    
}