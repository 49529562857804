(function (window) {
    class Slider {

        #sliderContainer
        // array of html elements
        #arrayCarouselItems
        // array of html elements -> btn indicators
        #arrayBtnChangeSlide
        #currentSlideIndex
        #carouselItemsLength
        #intervalTime
        #intervalId

        /**
         * 
         * @param {string} sliderContainer 
         */
        constructor(sliderContainer) {
            if (!sliderContainer) {
                console.log('sliderContainer is not defined')
            }
            this.#sliderContainer = document.querySelector(sliderContainer)
            if (!this.#sliderContainer) {
                console.log('element not found')
            } else {
                this.#intervalTime = 5000 // by security, will be updated from data-bs-interval
                this.#currentSlideIndex = 0
                this.#hydrate()

                if (this.#arrayCarouselItems) {

                    // launch the slider 
                    this.#handleSlideInterval()

                    // set click event for btn
                    if (this.#arrayBtnChangeSlide) {
                        this.#addEventListenerForBtn()
                    }
                }
            }
        }

        #hydrate() {
            // array of carousel items elements and length
            const carouselItems = this.#sliderContainer.querySelectorAll('.carousel-item')
            if (carouselItems) {
                this.#arrayCarouselItems = Array.from(carouselItems)
                this.#carouselItemsLength = this.#arrayCarouselItems.length
            }

            // array of carousel indicators btn
            const btnsChangeSlide = this.#sliderContainer.querySelectorAll('.carousel-indicators button')
            if (btnsChangeSlide) {
                this.#arrayBtnChangeSlide = Array.from(btnsChangeSlide)
            }

            // get interval set in the dom
            const intervalSecondes = this.#sliderContainer.dataset.bsInterval
            if (intervalSecondes) {
                this.#intervalTime = intervalSecondes
            }
        }

        #handleSlideChange() {
            this.#arrayCarouselItems.map((carouselItems, i) => {
                // if the index is the currentSlide index -> show the slide else hide the slides
                if (i === parseInt(this.#currentSlideIndex)) {
                    carouselItems.classList.remove('hidden')
                } else {
                    carouselItems.classList.add('hidden')
                }
            })
        }

        #handleIndicatorsChange() {
            // remove class for previous selected indicator
            this.#arrayBtnChangeSlide.map((btn, i) => {
                if (i === parseInt(this.#currentSlideIndex)) {
                    btn.classList.add('active')
                } else {
                    btn.classList.remove('active')
                }
            })
        }

        // on every "secondes" it change the current slide
        #handleSlideInterval() {
            this.#intervalId = setInterval(() => {
                // chnage the current slide index
                if (this.#currentSlideIndex === this.#carouselItemsLength - 1) {
                    this.#currentSlideIndex = 0
                } else {
                    this.#currentSlideIndex += 1
                }
                this.#handleIndicatorsChange()
                this.#handleSlideChange()
            }, this.#intervalTime);
        }

        #addEventListenerForBtn() {
            // loop thru indicators to add event listener
            this.#arrayBtnChangeSlide.map((btnChangeSlide) => {
                const slideToShow = btnChangeSlide.dataset.bsSlideTo
                // On click on btn indicators, change the current carousel item displayed
                btnChangeSlide.addEventListener('click', () => {
                    // update index
                    this.#currentSlideIndex = parseInt(slideToShow)

                    if (this.#intervalId > 0) {
                        // prevent to have an auto slide just after a click for exemple
                        // clear the previous interval to recreate a new one
                        clearInterval(this.#intervalId)
                    }

                    // update dom
                    this.#handleIndicatorsChange()
                    this.#handleSlideChange()
                    // re-launch an interval
                    this.#handleSlideInterval()
                })
            })
        }

        updateInterval(intervalSecondes){
            if (intervalSecondes) {
                this.#intervalTime = intervalSecondes
            }
            if (this.#intervalId > 0) {
                // prevent to have an auto slide just after a click for exemple
                // clear the previous interval to recreate a new one
                clearInterval(this.#intervalId)
            }
            // re-launch an interval
            this.#handleSlideInterval()
        }

    }
    // used to export globally the class
    window.Slider = Slider;

})(window);